import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "@emotion/styled"
import Github from "../assets/github.svg"
import Linkedin from "../assets/linkedin.svg"

const Bar = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  justify-items: center;
  padding: 1em 1em 0 1em;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const NavItem = styled.div`
  padding: 0 1em 0 1em;
`;

const ResumeLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  border: 1px solid;
  border-radius: 5px;
  padding: 0.25em;
`;

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          links {
            github
            linkedin
            resume
          }
        }
      }
    }
  `)

  const links = data.site.siteMetadata?.links
  
  return (
    <Bar>
      <Container>
        <Link style={{textDecoration: "none"}} to="/">
          <h2>Armand Picard</h2>
        </Link>
      </Container>
      <Container>
        <NavItem>
          <Link to={links.github} target="_blank">
            <Github height="2em" width="2em"/>
          </Link>
        </NavItem>

        <NavItem>
          <Link to={links.linkedin} target="_blank">
            <Linkedin height="2em" width="2em"/>
          </Link>
        </NavItem>

        <NavItem>
          <ResumeLink href={links.resume} target="_blank">
            Resume
          </ResumeLink>
        </NavItem>
      </Container>
    </Bar>
  );
};

export default Header;
