module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[null],"rehypePlugins":[[null,{"strict":"ignore"}]],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/armand/work/perso/blog/armandpicard","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Armand Picard","short_name":"Armand Picard","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"src/images/profile-pic.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ad10596f88528e1bc82b78ab1caf6d53"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/armand/work/perso/blog/armandpicard/src/layout.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
