import * as React from "react"
import Header from "./components/header"
import styled from "@emotion/styled";

const Footer = () => (
  <footer>
      <hr/>
      <p
        align="center"
        >
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </p>
  </footer>
)

const Main = styled.main`
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
`

const Layout = ({ children }) => {
  return (
    <Main>
      <Header/>
      {children}
      <Footer/>
    </Main>
  )
}

export default Layout
